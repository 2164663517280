.Tombol {
    background-color: #708871;
    color: #FEF3E2;
    padding: 0.5em 1em;
    margin: 0.25em;
    border-radius: 1em;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    transition: background-color 0.3s;
    font-size: 1.5rem;
}



.Tombol:hover {
    background-color: #556b2f;
}

.ButtonContainer{
    display: flex;
    justify-content: flex-end;
    background-color: #BEC6A0;
}

.Banner{
    display: flex;
    background-color: #BEC6A0 ;
    justify-content: space-between;
    align-items: center;
}


.pope{
    display: block;
    max-width:150px;
    max-height: 150px;
    width: auto;
    height: auto;
    background-color: #BEC6A0 ;
    margin-right: 20px;
}

.logo{
    display: block;
    max-width:540px;
    max-height: 135px;
    width: auto;
    height: auto;
    background-color: #BEC6A0 ;
}

.Title{
    text-align: left;
}

.ltitle{
    background-color: #BEC6A0 ;
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
    font-size: 36px;
    letter-spacing: 0px;
    word-spacing: 2px;
    color: #dcce64;
    -webkit-text-stroke: 1px black;
    font-weight: 700;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
}

.mtitle{
    background-color: #BEC6A0 ;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 36px;
    letter-spacing: 2px;
    word-spacing: 2px;
    color: #000;
    font-weight: 700;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
}

.stitle{
    background-color: #BEC6A0 ;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    letter-spacing: 2px;
    word-spacing: 2px;
    color: #000;
    font-weight: 700;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
}

.ls{
    display: flex;
}

.lowStyle{
    height: 10px;
    background-color: #556b2f;
}

@media(max-width: 890px){
    .logo{
        display: none;
    }
}

@media (max-width: 640px){
    .Tombol{
        font-size: 0.8rem;
    }

    .Banner{
        max-height: 50%;
    }
    .pope{
        max-width:50px;
        max-height: 50px;
    }

    .ltitle,.mtitle{
        font-size: 16px;
    }

    .stitle{
        font-size: 9px;
    }

    .lowStyle{
        height: 5px;
    }
}

@media (max-width: 430px) {
    .Tombol{
        font-size: 0.7rem;
    }
}
