.title{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 36px;
    font-weight: bold;
    color: #435325;
}

.tableContainer{
    display: flex;
    justify-content: center;
}


.masterparent{
    width: 80%;
    margin: 0 auto;
    max-width: 80%;
    box-sizing: border-box;
}

.tombol {
    position: fixed;
    bottom: 0.5em;
    right: 30px;
    background-color: #708871;
    color: #FEF3E2;
    border-radius: 1em;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    transition: background-color 0.3s;
    font-size: 1.5rem;
}

.tombol:hover{
    background-color: #556b2f;
}

@media (max-width:820px) {
    .tombol{
        bottom: auto;
        top: 1.5em;
        right: 5px;
        font-size: 1rem;
    }
}