.pageContainer{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FEF3E2 ;
    overflow-y:auto 
}


.bottom{
    margin-bottom: 50px;
}